type QueryFunctionResponseParameters<T, R = void> = {
	apiFunction: (
		url: string,
		getToken: () => Promise<string>,
	) => Promise<Response>;
	url: string;
	getToken: () => Promise<string>;
	fromDto?: (dto: T) => R;
};

export async function handleQueryFunctionResponse<T, R = void>({
	apiFunction,
	url,
	getToken,
	fromDto,
}: QueryFunctionResponseParameters<T, R>): Promise<T | R> {
	const response = await apiFunction(url, getToken);
	const { ok, status } = response;

	if (ok) {
		const dto: T = await response.json();
		return fromDto ? fromDto(dto) : dto;
	}

	if (status === 404) {
		throw new Error("The item was not found");
	}

	throw new Error(`An unexpected error occurred: status code - ${status}`);
}
