export const displayValue = (value: string | undefined) => {
	if (!value) {
		return "";
	}
	return value;
};

export const numericDisplayValue = (
	value: number | undefined,
	decimals = 2,
) => {
	if (!value) {
		return "0";
	}
	return `${Number(value).toFixed(decimals)}`;
};

export const moneyDisplayValue = (
	value: number | string | undefined,
	withEur = false,
): string => {
	const numericValue = Number(value);
	if (Number.isNaN(numericValue)) {
		return "0";
	}
	if (withEur) {
		return new Intl.NumberFormat("de-DE", {
			style: "currency",
			currency: "EUR",
		}).format(numericValue);
	}
	return new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(
		numericValue,
	);
};

export const convertToNumber = (value: string): number => {
	let sanitizedValue = value.replace(/[^\d.,]/g, "");

	sanitizedValue = sanitizedValue.replace(",", ".");

	return Number.parseFloat(sanitizedValue);
};

export const removeCurrencyDisplayValue = (value: string): string => {
	return value.replace(/\s+€/, "").replace(/-\s/, "");
};

export const removeMoneyDisplayValue = (value: string): string => {
	return value.replace(/\s+€/, "").replace(/\./g, "").replace(/,/, ".");
};

export const signedMoneyDisplayValue = (
	value: string,
	hasNegativeSign: boolean,
) => {
	if (hasNegativeSign) {
		return `- ${moneyDisplayValue(convertToNumber(value), true)}`;
	}
	return `${moneyDisplayValue(convertToNumber(value), true)}`;
};
