import React from "react";

export const SmallDropdownIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="7"
			height="5"
			viewBox="0 0 7 5"
			fill="none"
		>
			<title>Small Dropdown Icon Outline</title>
			<path
				d="M0.656738 0.572266L3.51388 3.42941L6.37102 0.572267"
				stroke="#3C3C3B"
				strokeOpacity="0.5"
			/>
		</svg>
	);
};
