import React from "react";

import { Loading } from "../icons/Loading";
import { MegaWattsLogo } from "../icons/MegaWattsLogo";

import s from "./styles.module.css";

export const LoadingScreen = () => {
	return (
		<div className={s.layout}>
			<div className={s.content}>
				<div>
					<MegaWattsLogo />
				</div>
				<div>
					<Loading cssClass={s.loading} />
				</div>
			</div>
		</div>
	);
};
