import * as React from "react";
import { createFileRoute } from "@tanstack/react-router";
import {
	type UseQueryOptions,
	useQueryErrorResetBoundary,
} from "@tanstack/react-query";
import { getOfferQueryOptions } from "@repo/api/getOfferQueryOptions";
import type { OfferModel } from "@repo/api/models";
import { OfferPage } from "../pages/OfferPage";

import s from "../styles/offer-creation.module.css";

type OfferSearch = {
	offerCollectionID: string;
	customerID: string;
};

const apiUrl = import.meta.env.VITE_APP_API_URL;

export const Route = createFileRoute("/_auth/offer-creation")({
	validateSearch: (search: Record<string, unknown>): OfferSearch => {
		// validate and parse the search params into a typed state
		return {
			offerCollectionID: (search.offerCollectionID as string) || "",
			customerID: (search.customerID as string) || "",
		};
	},
	loaderDeps: ({ search: { offerCollectionID, customerID } }) => ({
		offerCollectionID,
		customerID,
	}),
	loader: async ({
		context: { queryClient, auth },
		deps: { offerCollectionID },
	}) =>
		queryClient.ensureQueryData(
			getOfferQueryOptions(
				`${apiUrl}/offers/${offerCollectionID}`,
				auth.getToken,
			) as UseQueryOptions<OfferModel | { message: string }, Error>,
		),
	errorComponent: ({ error }) => {
		const queryErrorResetBoundary = useQueryErrorResetBoundary();

		React.useEffect(() => {
			// Reset the query error boundary
			queryErrorResetBoundary.reset();
		}, [queryErrorResetBoundary]);

		return <div className={s.error}>{error.message}</div>;
	},
	component: OfferPage,
});
