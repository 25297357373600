import type {
	CalculatePayload,
	OfferModel,
	OfferMutationModel,
} from "@repo/api/models";
import {
	type Action as ModalAction,
	ActionKind as ModalActionKind,
} from "@repo/state/modalResponseReducer";
import {
	type LineItemsAction,
	LineItemsActionKind,
} from "@repo/state/reducers";
import type { UseMutationResult } from "@tanstack/react-query";

import { getSummary } from "../../helpers/getSummary";

export interface OnOverwriteProps {
	dispatch: ({ type, payload }: LineItemsAction) => void;
	dispatchModal: ({ type, payload }: ModalAction) => void;
	mutation: UseMutationResult<Response, Error, OfferMutationModel, unknown>;
	mutationCalculation: UseMutationResult<
		Response,
		Error,
		CalculatePayload,
		unknown
	>;
	offerData: OfferModel;
}

export const onOverwrite = async ({
	dispatch,
	dispatchModal,
	mutation,
	mutationCalculation,
	offerData,
}: OnOverwriteProps) => {
	const responseCalculation = await mutationCalculation.mutateAsync({
		lineItems: offerData.lineItems,
		vat: offerData.vat,
	});

	const summary = await getSummary({
		responseCalculation,
		dispatchModal,
		dispatch,
	});

	const response = summary
		? await mutation.mutateAsync({
				...offerData,
				...summary,
				...{ originalOfferId: undefined },
			})
		: { ok: false };

	if (!response.ok) {
		dispatchModal({
			type: ModalActionKind.ERROR_FETCHING,
			payload: {
				errorFetching: true,
				message:
					"We were not able to generate the offer successfully. Please, try again and if error persist get in touch with the Tech team.",
				title: "Something went wrong",
				responseType: "error",
				createOffer: false,
				updateOffer: false,
			},
		});
	}
	if (response.ok) {
		dispatch({
			type: LineItemsActionKind.REMOVE_ALL_EMPTY,
			payload: {},
		});
		dispatchModal({
			type: ModalActionKind.OVERWRITTEN_OFFER,
		});
	}
};
