import React from "react";
import type { ReactNode } from "react";
import s from "./styles.module.css";
import { AuthenticationCard } from "../authentication-card/AuthenticationCard";

export const AuthenticationScreen = ({ children }: { children: ReactNode }) => {
	return (
		<div className={s.layout}>
			<AuthenticationCard>{children}</AuthenticationCard>
		</div>
	);
};
