import React from "react";

import { Success as SuccessIcon, ErrorIcon } from "../icons";
import styles from "./styles.module.css";

export interface ResponseStatusProps {
	message?: string;
	offerNumber?: string;
	title?: string;
	responseType?: "error" | "success";
	sfdcLink?: string;
}

export const ResponseStatus = ({
	message,
	offerNumber,
	title,
	responseType,
	sfdcLink,
}: ResponseStatusProps) => {
	return (
		<div className={styles.layout}>
			<div className={styles.response}>
				{responseType === "success" && <SuccessIcon />}
				{responseType === "error" && <ErrorIcon />}
				<p className={styles.title}>{title}</p>
				<p className={styles.description}>{message}</p>
				{offerNumber && responseType !== "error" && (
					<div className={styles.responseDetails}>
						<span className={styles.responseLabel}>Offer number:</span>
						<a href={sfdcLink} target="_blank" rel="noreferrer">
							<span className={styles.responseText}>{offerNumber}</span>
						</a>
					</div>
				)}
			</div>
		</div>
	);
};
