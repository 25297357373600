import * as jose from "jose";

const storageKeys = {
	user: "megawatts.auth.user",
	picture: "megawatts.auth.picture",
	state: "megawatts.auth.state",
	token: "megawatts.auth.token",
	expiration: "megawatts.auth.expiration",
	refresh: "megawatts.auth.refresh",
	redirect: "megawatts.auth.redirect",
};

function getStoredItem(key: string) {
	return localStorage.getItem(key) || "";
}

function setStoredItem(key: string, value: string) {
	if (value) {
		localStorage.setItem(key, value);
	} else {
		localStorage.removeItem(key);
	}
}

export const setStoredUserAndPicture = (encodedData: string) => {
	const decodedData = encodedData ? jose.decodeJwt(encodedData) : {};
	setStoredUser(`${decodedData?.given_name}`);
	setStoredPicture(`${decodedData?.picture}?dma_cps=sypham`);
};

export const getStoredUser = () => getStoredItem(storageKeys.user);
export const setStoredUser = (value: string) =>
	setStoredItem(storageKeys.user, value);

export const getStoredPicture = () => getStoredItem(storageKeys.picture);
export const setStoredPicture = (value: string) =>
	setStoredItem(storageKeys.picture, value);

export const getStoredState = () => getStoredItem(storageKeys.state);
export const setStoredState = (): string => {
	// Function to generate a random state value
	const generateCryptoRandomState = () => {
		const randomValues = new Uint32Array(2);
		window.crypto.getRandomValues(randomValues);
		const numberArray: number[] = Array.from(randomValues);

		// Encode as UTF-8
		const utf8Encoder = new TextEncoder();
		const utf8Array = utf8Encoder.encode(
			String.fromCharCode.apply(null, numberArray),
		);
		const textArray: number[] = Array.from(utf8Array);

		// Base64 encode the UTF-8 data
		return btoa(String.fromCharCode.apply(null, textArray))
			.replace(/\+/g, "-")
			.replace(/\//g, "_")
			.replace(/=+$/, "");
	};

	const state = generateCryptoRandomState();
	setStoredItem(storageKeys.state, state);
	return state;
};

export const getStoredToken = () => getStoredItem(storageKeys.token);
export const setStoredToken = (value: string) =>
	setStoredItem(storageKeys.token, value);

export const getStoredTokenExpiration = (): boolean => {
	const expirationTime = Number(getStoredItem(storageKeys.expiration));
	return expirationTime < new Date().getTime();
};
export const setStoredTokenExpiration = (value: string) => {
	const expirationTime = new Date();
	expirationTime.setSeconds(expirationTime.getSeconds() + Number(value));
	setStoredItem(storageKeys.expiration, String(expirationTime.getTime()));
};

export const getStoredRefreshToken = () => getStoredItem(storageKeys.refresh);
export const setStoredRefreshToken = (value: string) =>
	setStoredItem(storageKeys.refresh, value);

export const getStoredRedirect = () => getStoredItem(storageKeys.redirect);
export const setStoredRedirect = (value: string) =>
	setStoredItem(storageKeys.redirect, value);

export const clearStorage = () => {
	for (const key of Object.values(storageKeys)) {
		localStorage.removeItem(key);
	}
};
