import * as React from "react";
import {
	Outlet,
	createFileRoute,
	redirect,
	useRouter,
} from "@tanstack/react-router";

import { useAuth } from "../../../../packages/api/src/auth";
import { Header } from "@repo/ui/Header";

export const Route = createFileRoute("/_auth")({
	beforeLoad: ({ context, location }) => {
		if (!context.auth.isAuthenticated) {
			throw redirect({
				to: "/login",
				search: {
					redirect: location.href,
				},
			});
		}
	},
	component: AuthLayout,
});

function AuthLayout() {
	const router = useRouter();
	const navigate = Route.useNavigate();
	const auth = useAuth();

	const handleLogout = () => {
		if (window.confirm("Are you sure you want to logout?")) {
			auth.logout();
			const redirectUri = `/login?redirect=${encodeURIComponent(
				`${location.pathname}${location.search}`,
			)}`;
			router.invalidate().finally(() => {
				navigate({ to: redirectUri });
			});
		}
	};

	return (
		<React.Fragment>
			<Header
				handleLogout={handleLogout}
				name={auth.user}
				picture={auth.userPicture}
			/>
			<Outlet />
		</React.Fragment>
	);
}
