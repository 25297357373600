import React from "react";

export const Loading = ({ cssClass }: { cssClass: string }) => {
	return (
		<svg
			width="240"
			height="4"
			viewBox="0 0 240 4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Loading</title>
			<g id="Group 40">
				<rect
					id="Rectangle 68"
					width="240"
					height="4"
					rx="2"
					fill="#00624F"
					fillOpacity="0.3"
				/>
				<rect
					className={cssClass}
					id="Rectangle 69"
					width="0"
					height="4"
					rx="2"
					fill="#00624F"
				/>
			</g>
		</svg>
	);
};
