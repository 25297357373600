import type {
	CalculatePayload,
	CalculatePayloadApiCall,
} from "../models/calculate.model";
import { toDto } from "../models/calculate.model";

export const mutationCalculate = async (
	url: string,
	getToken: () => Promise<string>,
	calculatePayload: CalculatePayload,
) => {
	const token = await getToken();
	const offerToApiModel = toDto(calculatePayload) as CalculatePayloadApiCall;

	return await fetch(url, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(offerToApiModel),
	});
};
