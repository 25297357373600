import React from "react";
import { Logout, SunheroLogo } from "../icons";

import s from "./styles.module.css";

type HeaderProps = {
	name: string | unknown | undefined;
	picture: string | unknown | undefined;
	handleLogout: () => void;
};

export const Header = ({ name, picture, handleLogout }: HeaderProps) => {
	return (
		<header className={s.layout}>
			<div className={s.content}>
				<div className={s.section}>
					<span className={s.homebutton}>
						<SunheroLogo />
					</span>
					<span className={s.title}>Offer line items</span>
				</div>
				<div className={s.section}>
					{typeof name === "string" && typeof picture === "string" && (
						<>
							<span className={s.title}>Hi {name}!</span>
							<img
								className={s.avatar}
								src={picture}
								alt={name}
								referrerPolicy="no-referrer"
							/>
						</>
					)}
					<span
						className={s.logout}
						onKeyDown={handleLogout}
						onClick={handleLogout}
					>
						<Logout />
					</span>
				</div>
			</div>
		</header>
	);
};
