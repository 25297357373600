// biome-ignore lint/style/useImportType: <explanation>
import React from "react";

import s from "./styles.module.css";

export function DropdownList({
	children,
	...props
}: { children: React.ReactNode }) {
	return (
		<ul className={s.list} {...props}>
			{children}
		</ul>
	);
}
