import type { LineItemModel } from "../models";
import type { MaterialWithAssociatedMaterialsApiResponseModel } from "../models/materials-response.model";
import type { LineItemApiResponseModel } from "../models/offer-response.model";

const ProductTypeApiNames: Record<string, string> = {
	panel: "Panel",
	inverter: "Inverter",
	installation: "Installation",
	installation_cost: "Installation",
	battery: "Battery Storage",
	battery_accessory: "Battery Accessories",
	service: "Service",
	mounting_systems: "Mounting Systems",
	add_on: "Add On",
	accessory: "Accessory",
	generic_accessory: "Accessory",
	inverter_accessories: "Accessory",
	discount: "Discount",
};

export enum ApiMaterialType {
	Panel = "panel",
	Inverter = "inverter",
	Installation = "installation",
	"Inverter Accessories" = "Inverter Accessories",
	"Battery Storage" = "battery",
	"Battery Accessories" = "battery_accessory",
	Service = "service",
	"Mounting Systems" = "Mounting Systems",
	"Add On" = "add_on",
	Accessory = "accessory",
	Discount = "discount",
}

function getKeyByValue(value: string): string | undefined {
	return ProductTypeApiNames[value];
}

function isSourceInApiMaterialType(value: string): boolean {
	return Object.keys(ApiMaterialType).includes(
		value as unknown as ApiMaterialType,
	);
}

export function materialTypeConversion(source: string) {
	if (isSourceInApiMaterialType(source)) {
		return source;
	}

	return getKeyByValue(source);
}

function isMaterialWithAssociatedMaterialsApiResponseModel(
	material:
		| LineItemModel
		| LineItemApiResponseModel
		| MaterialWithAssociatedMaterialsApiResponseModel,
): material is MaterialWithAssociatedMaterialsApiResponseModel {
	return (
		(material as MaterialWithAssociatedMaterialsApiResponseModel)
			.material_type !== undefined
	);
}

export function isDiscount(
	material:
		| LineItemModel
		| LineItemApiResponseModel
		| MaterialWithAssociatedMaterialsApiResponseModel,
): boolean {
	if ("type" in material && material.type) {
		return ["discount", "Discount"].includes(material.type);
	}
	if (isMaterialWithAssociatedMaterialsApiResponseModel(material)) {
		return ["discount", "Discount"].includes(material.material_type);
	}
	return false;
}

export function isIntallationType(type: string): boolean {
	return ["installation", "installation_cost", "Installation"].includes(type);
}

export function isInstallation(
	material:
		| LineItemModel
		| LineItemApiResponseModel
		| MaterialWithAssociatedMaterialsApiResponseModel,
): boolean {
	if ("type" in material && material.type) {
		return isIntallationType(material.type);
	}
	if (isMaterialWithAssociatedMaterialsApiResponseModel(material)) {
		return isIntallationType(material.material_type);
	}
	return false;
}
