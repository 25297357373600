import React from "react";
import { useState } from "react";

import { StackedContainer } from "../stacked-container/StackedContainer";
import { Detail, type detailRowProps } from "./Detail";

import styles from "./styles.module.css";

interface CustomerDetailsProps {
	details: detailRowProps[];
	documents: string[];
	offerId: string;
}

export const CustomerDetails = ({
	details,
	documents = [],
	offerId,
}: CustomerDetailsProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const amountDocuments = documents.length;
	return (
		<div className={styles.layoutClientDocument}>
			<StackedContainer
				variant="expandable"
				isExpanded={isExpanded}
				setContainerState={setIsExpanded}
			>
				<Detail
					variant="detailTitle"
					hasDetailsText={!isExpanded}
					details={{
						label: "customer details",
						value: `(Offer ID - ${offerId})`,
					}}
				/>
				{isExpanded &&
					details.map((detail) => (
						<Detail
							key={detail.label}
							variant="detailRow"
							hasDetailsText
							details={{
								label: detail.label,
								value: detail.value,
							}}
						/>
					))}
			</StackedContainer>
			<StackedContainer variant="default">
				<Detail
					variant="detailTitle"
					hasDetailsText
					details={{
						label: "associated documents",
						value: `(${amountDocuments})`,
					}}
				/>
			</StackedContainer>
		</div>
	);
};
