import React from "react";
import {
	ErrorComponent,
	RouterProvider,
	createRouter,
} from "@tanstack/react-router";
import { AuthProvider, useAuth } from "../../../packages/api/src/auth";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { LoadingScreen } from "@repo/ui/LoadingScreen";

import { routeTree } from "./routeTree.gen";

const queryClient = new QueryClient();

const router = createRouter({
	defaultPendingComponent: () => <LoadingScreen />,
	defaultErrorComponent: ({ error }: { error: Error }) => (
		<ErrorComponent error={error} />
	),
	routeTree,
	defaultPreload: "intent",
	context: {
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		auth: undefined!, // This will be set after we wrap the app in an AuthProvider
		queryClient: queryClient,
	},
});

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

function InnerApp() {
	const auth = useAuth();
	return <RouterProvider router={router} context={{ auth }} />;
}

function App() {
	return (
		<AuthProvider>
			<QueryClientProvider client={queryClient}>
				<InnerApp />
			</QueryClientProvider>
		</AuthProvider>
	);
}

export default App;
