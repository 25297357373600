import type { SearchableTerm, GroupedSuggestions } from "./types";

export function groupBy(
	arr: Array<SearchableTerm>,
	fn: (item: SearchableTerm) => string,
): GroupedSuggestions {
	return arr.reduce<Record<string, Array<SearchableTerm>>>((prev, curr) => {
		const groupKey = fn(curr);
		const group = prev[groupKey] || [];
		group.push(curr);
		// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
		return { ...prev, [groupKey]: group };
	}, {});
}
