import React, { useCallback } from "react";
import type { OfferModel } from "@repo/api/models";
import { onUpdatePrices, type OnUpdatePricesProps } from "./onUpdatePrices";
import { Button } from "../button/Button";
import { LoadingScreen } from "../loading-screen/LoadingScreen";
import { moneyDisplayValue } from "../../helpers/format";

import styles from "./styles.module.css";

interface PriceDifferencesProps extends OnUpdatePricesProps {
	onCloseButtonClick: () => void;
	modalToggle: () => void;
}

const getMaterialBySku = (offer: OfferModel, sku: string) =>
	offer.lineItems.find((material) => material.sku === sku);

export function PriceDifferences({
	onCloseButtonClick,
	mutationCalculation,
	offerData,
	dispatchModal,
	dispatch,
	materialsPriceDifferences,
	modalToggle,
}: PriceDifferencesProps) {
	const handleOnSubmit = useCallback(() => {
		onUpdatePrices({
			dispatch,
			dispatchModal,
			mutationCalculation,
			offerData,
			materialsPriceDifferences,
		});
		modalToggle();
	}, [
		dispatch,
		dispatchModal,
		modalToggle,
		mutationCalculation,
		offerData,
		materialsPriceDifferences,
	]);

	return (
		<div className={styles.layout}>
			<p className={styles.title}>Price differences</p>
			<div className={styles.copyForm}>
				<p className={styles.description}>
					The following products/services have a different price in Holded.
				</p>
			</div>
			<div className={styles.materialsTable}>
				<div className={styles.materialsHeader}>
					<span>Product/Service Name</span>
					<span>Current offer price</span>
					<span>Holded price</span>
				</div>
				<div className={styles.materialsRows}>
					{materialsPriceDifferences?.map((material) => (
						<div key={material.sku} className={styles.materialsRow}>
							<span>{material.display_name}</span>
							<span>
								{moneyDisplayValue(
									getMaterialBySku(offerData, material.sku)
										?.priceIncludingTaxes,
									true,
								)}
							</span>
							<span>
								{moneyDisplayValue(material.price_with_margin_and_taxes, true)}
							</span>
						</div>
					))}
				</div>
			</div>
			{mutationCalculation?.isPending && <LoadingScreen />}
			<div className={styles.copyForm}>
				<p className={styles.description}>
					Updating the prices will affect all items shown above and the total
					price of the offer. Please involve your manager to determine the
					prices to apply.
				</p>
			</div>
			<div className={styles.cta}>
				<Button
					isDisabled={mutationCalculation?.isPending}
					onClick={onCloseButtonClick}
					variant="white"
					type="button"
				>
					cancel
				</Button>
				<Button
					variant="green"
					type="submit"
					isDisabled={mutationCalculation?.isPending}
					onClick={handleOnSubmit}
				>
					update prices
				</Button>
			</div>
		</div>
	);
}
