// biome-ignore lint/style/useImportType: <explanation>
import React from "react";

import { DropdownIconOutline } from "../icons";
import styles from "./styles.module.css";

interface StackedContainerProps {
	children: React.ReactNode;
	variant: "default" | "expandable";
	isExpanded?: boolean;
	setContainerState?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StackedContainer = ({
	children,
	variant,
	setContainerState,
	isExpanded,
}: StackedContainerProps) => {
	const handleOnClick = () => {
		if (variant === "expandable" && setContainerState) {
			setContainerState(!isExpanded);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleOnClick();
		}
	};

	return (
		<div className={styles.layout}>
			{variant === "expandable" && (
				<div
					className={`${styles.dropdown} ${isExpanded && styles.expanded}`}
					onClick={handleOnClick}
					onKeyDown={handleKeyDown}
				>
					<DropdownIconOutline />
				</div>
			)}
			{children}
		</div>
	);
};
