import { validLineItems } from "../helpers/line-items-utils";
import type { LineItemApiResponseModel } from "./offer-response.model";
import type { LineItemModel } from "./offer.model.ts";

export interface CalculationSummaryModel {
	totalAmountExcludingDiscounts: string;
	totalAmountIncludingDiscounts: string;
	totalPriceIncludingTaxes: string;
	totalPriceExcludingTaxes: string;
	totalCost: string;
	discountAmount: string;
	discountPercentage: string;
	vat: string;
	marginAmount: string;
	marginPercentage: string;
	marginExcludingDiscountAmount: string;
	marginExcludingDiscountPercentage: string;
}

export interface CalculateModel extends CalculationSummaryModel {
	isMatchingPrices: boolean;
}

export interface CalculationSummaryApiResponseModel {
	total_amount_excluding_discounts: number;
	total_amount_including_discounts: number;
	total_price_including_taxes: number;
	total_price_excluding_taxes: number;
	total_cost: number;
	discount_amount: number;
	discount_percentage: number;
	vat: number;
	margin_amount: number;
	margin_percentage: number;
	margin_excluding_discount_amount: number;
	margin_excluding_discount_percentage: number;
}

export interface CalculateApiResponseModel
	extends CalculationSummaryApiResponseModel {
	is_matching_prices: boolean;
}

export interface CalculatePayload {
	lineItems: LineItemModel[];
	vat: string;
}

export interface CalculatePayloadApiCall {
	line_items: LineItemApiResponseModel[];
	vat: string;
}

/**
 * Transform a Domain model to an API model
 * @param dto Domain model
 * @returns API model
 */
export function fromDto(dto: CalculateApiResponseModel): CalculateModel {
	return {
		isMatchingPrices: dto.is_matching_prices,
		totalAmountExcludingDiscounts:
			dto.total_amount_excluding_discounts.toString(),
		totalAmountIncludingDiscounts:
			dto.total_amount_including_discounts.toString(),
		totalPriceIncludingTaxes: dto.total_price_including_taxes.toString(),
		totalPriceExcludingTaxes: dto.total_price_excluding_taxes.toString(),
		totalCost: dto.total_cost.toString(),
		discountAmount: dto.discount_amount.toString(),
		discountPercentage: dto.discount_percentage.toString(),
		vat: dto.vat.toString(),
		marginAmount: dto.margin_amount.toString(),
		marginPercentage: dto.margin_percentage.toString(),
		marginExcludingDiscountAmount:
			dto.margin_excluding_discount_amount.toString(),
		marginExcludingDiscountPercentage:
			dto.margin_excluding_discount_percentage.toString(),
	};
}

/**
 * Transform a Domain model to an API model
 * @param dto Domain model
 * @returns API model
 */
export function toDto(dto: CalculatePayload): CalculatePayloadApiCall {
	return {
		line_items: validLineItems(dto.lineItems),
		vat: dto.vat.toString(),
	};
}
