import React from "react";

export const Delete = () => {
	return (
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none">
			<title>delete</title>
			<path
				d="M11.4661 6V12.6667H6.13281V6H11.4661ZM10.4661 2H7.13281L6.46615 2.66667H4.13281V4H13.4661V2.66667H11.1328L10.4661 2ZM12.7995 4.66667H4.79948V12.6667C4.79948 13.4 5.39948 14 6.13281 14H11.4661C12.1995 14 12.7995 13.4 12.7995 12.6667V4.66667Z"
				fill="#3C3C3B"
				fillOpacity="0.5"
			/>
		</svg>
	);
};
