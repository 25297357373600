import { isIntallationType } from "@repo/api/materialTypeConversion";
import React, {
	type ChangeEvent,
	type KeyboardEvent,
	useRef,
	useState,
} from "react";
import { useClickOutside } from "../../../hooks";
import { AutocompleteInput } from "./Input";
import { Suggestions } from "./Suggestions";
import { groupBy } from "./groupBy";
import { hasSuggestionAndTermMatch, searchableTerms } from "./searchableTerms";
import type {
	GroupedSuggestions,
	SearchableTerm,
	SuggestionItem,
} from "./types";

import s from "./styles.module.css";

interface AutocompleteProps {
	terms: Array<SuggestionItem>;
	initialValue: string;
	sku?: string;
	type?: string;
	onChange: (suggestion: SearchableTerm) => void;
}

export const Autocomplete = ({
	terms,
	initialValue,
	sku = "",
	type = "",
	onChange,
}: AutocompleteProps) => {
	const containerRef = useRef(null);
	const [search, setSearch] = useState<string>(initialValue);
	const [suggestions, setSuggestions] = useState<GroupedSuggestions>({});
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] =
		useState<number>(0);

	useClickOutside(containerRef, () => {
		setSearch("");
		setSelectedSuggestionIndex(0);
	});

	const onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const searchTerm = e.target.value;
		setSearch(searchTerm);

		if (searchTerm) {
			const filterBySearchTerm = (term: SearchableTerm) =>
				hasSuggestionAndTermMatch(term.searchTerm, searchTerm);
			const suggestionsList = searchableTerms(terms).filter(filterBySearchTerm);
			setSuggestions(groupBy(suggestionsList, (suggestion) => suggestion.type));
		}
	};

	const getNumberOfSuggestions = () =>
		Object.values(suggestions).reduce((acc, curr) => acc + curr.length, 0);

	const onSuggestionClick = (suggestion: SearchableTerm) => {
		onChange(suggestion);
		setSearch(suggestion.name);
	};

	const onSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			const suggestionIndex = Math.max(
				0,
				Math.min(selectedSuggestionIndex, getNumberOfSuggestions() - 1),
			);
			if (suggestionIndex !== -1) {
				const suggestion = Object.values(suggestions).flat()[suggestionIndex];
				onChange(suggestion);
				setSearch(suggestion.name);
			}
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			setSelectedSuggestionIndex(Math.max(selectedSuggestionIndex - 1, 0));
		} else if (e.key === "ArrowDown") {
			e.preventDefault();
			setSelectedSuggestionIndex(
				Math.min(selectedSuggestionIndex + 1, getNumberOfSuggestions() - 1),
			);
		}
	};

	if (initialValue) {
		const skuDisplay = isIntallationType(type) ? <span>{sku}</span> : "";
		return (
			<div className={s["initial-value"]}>
				<span>{search}</span>
				{skuDisplay}
			</div>
		);
	}

	return (
		<div ref={containerRef} className={s["autocomplete-wrapper"]}>
			<AutocompleteInput
				id="autocomplete"
				value={search}
				onChange={onSearchChange}
				onKeyDown={onSearchKeyDown}
			/>
			<Suggestions
				term={search}
				suggestionsList={suggestions}
				selectedOption={selectedSuggestionIndex}
				onClick={onSuggestionClick}
			/>
		</div>
	);
};
