import React from "react";

export const Logout = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<title>Logout</title>
			<path
				d="M12.0013 23.9997H8.0013V7.99967L12.0013 7.99967V5.33301H8.0013C6.53464 5.33301 5.33464 6.53301 5.33464 7.99967V23.9997C5.33464 25.4663 6.53464 26.6663 8.0013 26.6663H12.0013V23.9997ZM20.0013 9.33301L18.1213 11.213L21.5613 14.6663L10.668 14.6663V17.333H21.5613L18.1213 20.7863L20.0013 22.6663L26.668 15.9997L20.0013 9.33301Z"
				fill="white"
				fillOpacity="0.5"
			/>
		</svg>
	);
};
