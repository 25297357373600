import React from "react";

export const Close = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<title>close</title>
			<path
				d="M25.3346 8.54602L23.4546 6.66602L16.0013 14.1193L8.54797 6.66602L6.66797 8.54602L14.1213 15.9993L6.66797 23.4527L8.54797 25.3327L16.0013 17.8793L23.4546 25.3327L25.3346 23.4527L17.8813 15.9993L25.3346 8.54602Z"
				fill="#3C3C3B"
				fillOpacity="0.5"
			/>
		</svg>
	);
};
