/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
export const oauth2SignIn = (state: string): void => {
	// Google's OAuth 2.0 endpoint for requesting an access token
	const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
	const googleClientId = import.meta.env.VITE_APP_GOOGLE_CONSOLE_CLIENT_ID;

	// Create element to open OAuth 2.0 endpoint in new window.
	const form = document.createElement("form");
	form.setAttribute("method", "GET"); // Send as a GET request.
	form.setAttribute("action", oauth2Endpoint);

	// Parameters to pass to OAuth 2.0 endpoint.
	const params: Record<string, string> = {
		client_id: googleClientId,
		redirect_uri: `${window.location.origin}/login`,
		scope:
			"https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
		state: state,
		response_type: "code",
		access_type: "offline",
		prompt: "consent",
	};

	// Add form parameters as hidden input values.
	for (const p in params) {
		const input = document.createElement("input");
		input.setAttribute("type", "hidden");
		input.setAttribute("name", p);
		input.setAttribute("value", params[p]);
		form.appendChild(input);
	}

	// Add form to page and submit it to open the OAuth 2.0 endpoint.
	document.body.appendChild(form);
	form.submit();
};

/*
 * Request token and refresh token from Google's OAuth 2.0 server.
 */
export const oauth2GetToken = async (code: string) => {
	const oauth2Endpoint = "https://oauth2.googleapis.com/token";
	const googleClientId = import.meta.env.VITE_APP_GOOGLE_CONSOLE_CLIENT_ID;
	const googleSecret = import.meta.env.VITE_APP_GOOGLE_SECRET;

	const response = await fetch(oauth2Endpoint, {
		method: "POST",
		body: JSON.stringify({
			code,
			redirect_uri: `${window.location.origin}/login`,
			client_id: googleClientId,
			client_secret: googleSecret,
			scope: "",
			grant_type: "authorization_code",
		}),

		// Adding headers to the request
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		},
	});

	return response.json();
};

/*
 * Request token using refresh token from Google's OAuth 2.0 server.
 */
export const oauth2RefreshToken = async (refreshToken: string) => {
	const oauth2Endpoint = "https://oauth2.googleapis.com/token";
	const googleClientId = import.meta.env.VITE_APP_GOOGLE_CONSOLE_CLIENT_ID;
	const googleSecret = import.meta.env.VITE_APP_GOOGLE_SECRET;

	const response = await fetch(oauth2Endpoint, {
		method: "POST",
		body: JSON.stringify({
			refresh_token: refreshToken,
			client_id: googleClientId,
			client_secret: googleSecret,
			scope: "",
			grant_type: "refresh_token",
		}),

		// Adding headers to the request
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		},
	});

	return response.json();
};
