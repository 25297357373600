import React from "react";
import type { GroupedSuggestions, SearchableTerm } from "../types";
import { SuggestionRow } from "./SuggestionRow";

import s from "./styles.module.css";

type SuggestionsProps = {
	term: string;
	suggestionsList: GroupedSuggestions;
	selectedOption: number;
	onClick: (suggestion: SearchableTerm) => void;
};

const categoryTitle = (category: string) =>
	category.replace(/_/gi, " ").toUpperCase();

export const Suggestions = ({
	suggestionsList,
	selectedOption,
	term,
	onClick,
}: SuggestionsProps) => {
	let itemsCounter = -1;
	return (
		term &&
		suggestionsList && (
			<div
				className={s.container}
				id="suggestions"
				role="listbox"
				aria-label="States"
			>
				{Object.keys(suggestionsList).map((category) => {
					return (
						<div key={category}>
							<h3>{categoryTitle(category)}</h3>
							<ul className={s.itemsContainer}>
								{suggestionsList[category].map((suggestion) => {
									itemsCounter++;
									return (
										<SuggestionRow
											key={suggestion.id}
											searchTerm={term}
											rowValue={suggestion.name}
											selected={itemsCounter === selectedOption}
											clickReturnValue={suggestion}
											onClick={onClick}
											sku={suggestion.sku}
											type={suggestion.type}
										/>
									);
								})}
							</ul>
						</div>
					);
				})}
			</div>
		)
	);
};
