import { validLineItems } from "../helpers/line-items-utils";
import type { LineItemApiResponseModel } from "./offer-response.model";
import type { LineItemModel } from "./offer.model.ts";

export interface CalculationSummaryPayload {
	discountAmount: string;
	discountPercentage: string;
	marginAmount: string;
	marginExcludingDiscountAmount: string;
	marginExcludingDiscountPercentage: string;
	marginPercentage: string;
	totalAmount: string;
	totalAmountIncludingDiscounts: string;
	totalCost: string;
	totalPriceExcludingTaxes: string;
	totalPriceIncludingTaxes: string;
	vat: string;
}

export interface OfferMutationModel extends CalculationSummaryPayload {
	description?: string;
	language?: string;
	lastModifiedBy?: string;
	lineItems: LineItemModel[];
	offerId?: string | null;
	offerName?: string;
	offerNumber?: string;
	opportunityDealId?: string;
	opportunityId: string;
	opportunityName: string;
	originalOfferId?: string;
	paymentType: string;
	status?: string;
	updateReason?: string;
	vat: string;
	versionNumber: number;
}

export interface CalculationSummary {
	discount_amount: number;
	discount_percentage: number;
	line_items: LineItemApiResponseModel[];
	margin_amount: number;
	margin_excluding_discount_amount: number;
	margin_excluding_discount_percentage: number;
	margin_percentage: number;
	total_amount_excluding_discounts: number;
	total_amount_including_discounts: number;
	total_cost: number;
	total_price_excluding_taxes: number;
	total_price_including_taxes: number;
	vat: string;
}

export interface OfferApiMutationModel {
	description?: string;
	discount_amount: number;
	discount_percentage: number;
	language?: string;
	last_modified_by: string;
	line_items: LineItemApiResponseModel[];
	margin_amount: number;
	margin_excluding_discount_amount: number;
	margin_excluding_discount_percentage: number;
	margin_percentage: number;
	offer_id?: string | null;
	offer_name?: string;
	offer_number?: string;
	opportunity_deal_id?: string;
	opportunity_id: string;
	opportunity_name: string;
	original_offer_id?: string;
	payment_type: string;
	status?: string;
	total_amount?: number;
	update_reason?: string;
	vat: string;
	version_number: number;
}

export function lineItemsFromDto(
	lineItems: LineItemApiResponseModel[],
): LineItemModel[] {
	return lineItems.map((lineItem) => ({
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		sku: lineItem.sku!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		name: lineItem.name!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		type: lineItem.type!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		priceIncludingTaxes: lineItem.price_including_taxes.toString()!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		priceExcludingTaxes: lineItem.price_excluding_taxes.toString()!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		cost: lineItem.cost.toString()!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		quantity: lineItem.quantity!,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		subtotal: lineItem.subtotal!,
	}));
}

/**
 * Transform a Domain model to an API model
 * @param dto Domain model
 * @returns API model
 */
export function toDto(dto: OfferMutationModel): OfferApiMutationModel {
	return {
		description: dto.description,
		discount_amount: Number(dto.discountAmount),
		discount_percentage: Number(dto.discountPercentage),
		language: dto.language,
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		last_modified_by: dto.lastModifiedBy!,
		margin_amount: Number(dto.marginAmount),
		margin_excluding_discount_amount: Number(dto.marginExcludingDiscountAmount),
		margin_excluding_discount_percentage: Number(
			dto.marginExcludingDiscountPercentage,
		),
		margin_percentage: Number(dto.marginPercentage),
		offer_id: dto.offerId,
		offer_name: dto.offerName,
		offer_number: dto.offerNumber,
		opportunity_deal_id: dto.opportunityDealId,
		opportunity_id: dto.opportunityId,
		opportunity_name: dto.opportunityName,
		original_offer_id: dto.originalOfferId,
		payment_type: dto.paymentType,
		status: dto.status,
		total_amount: Number(dto.totalAmount),
		update_reason: dto.updateReason,
		vat: dto.vat,
		version_number: dto.versionNumber,
		line_items: validLineItems(dto.lineItems),
	};
}
