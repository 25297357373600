import type { SuggestionItem, SearchableTerm } from "./types";

export const hasSuggestionAndTermMatch = (suggestion: string, term: string) =>
	suggestion.toLowerCase().includes(term.toLowerCase()) ||
	suggestion.toLowerCase().startsWith(term.toLowerCase());

export const searchableTerms = (
	terms: Array<SuggestionItem>,
): Array<SearchableTerm> => {
	return terms.map((term) => ({
		...term,
		searchTerm: `${term.type} ${term.name}`,
	}));
};
