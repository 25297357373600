import { queryOptions } from "@tanstack/react-query";

import { materialsKey } from "./keys";
import { getMaterials } from "../api/getMaterials";
import { handleQueryFunctionResponse } from "../helpers/helpers";
import type { MaterialsApiResponseModel, MaterialsModel } from "../models";
import { fromDto } from "../models/materials-response.model";

export const getMaterialsQueryOptions = (
	url: string,
	getToken: () => Promise<string>,
) =>
	queryOptions({
		queryKey: [materialsKey],
		retry: false,
		queryFn: () =>
			handleQueryFunctionResponse<MaterialsApiResponseModel, MaterialsModel>({
				apiFunction: getMaterials,
				url,
				getToken,
				fromDto,
			}),
	});
