import { type MutableRefObject, useEffect } from "react";

export function useClickOutside(
	ref: MutableRefObject<null | Element>,
	handler: (event?: MouseEvent) => void,
) {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				handler(event);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, handler]);
}
