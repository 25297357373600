// biome-ignore lint/style/useImportType: <explanation>
import React, { useRef } from "react";

import { type Action, ActionKind } from "@repo/state/modalResponseReducer";

import { useClickOutside } from "../../../hooks";
import { DropdownIcon } from "../../icons";
import styles from "./styles.module.css";

export interface OfferButtonProps {
	isExpanded: boolean;
	setExpandedState: React.Dispatch<React.SetStateAction<boolean>>;
	dispatch: ({ type, payload }: Action) => void;
	modalToogle: () => void;
}

export const OfferButton = ({
	isExpanded,
	setExpandedState,
	dispatch,
	modalToogle,
}: OfferButtonProps) => {
	const containerRef = useRef(null);

	useClickOutside(containerRef, () => {
		setExpandedState(false);
	});

	const handleOnClick = () => {
		setExpandedState(!isExpanded);
	};

	const handleOnClickGenerate = () => {
		dispatch({
			type: ActionKind.GENERATE_OFFER,
		});
		handleOnClick();
		modalToogle();
	};

	const handleOnClickOverwrite = () => {
		dispatch({
			type: ActionKind.OVERWRITE_OFFER,
		});
		handleOnClick();
		modalToogle();
	};

	return (
		<div className={styles.buttonWrapper} ref={containerRef}>
			{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div
				className={styles.layoutButton}
				onClick={handleOnClick}
				role="button"
			>
				<div className={styles.textButton}>GENERATE OFFER</div>
				<div className={`${styles.dropdown} ${isExpanded && styles.expanded}`}>
					<DropdownIcon />
				</div>
			</div>
			{isExpanded && (
				<div className={styles.actionsLayout}>
					{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
					<div className={styles.actionRow} onClick={handleOnClickGenerate}>
						Generate as new offer
					</div>
					{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
					<div className={styles.actionRow} onClick={handleOnClickOverwrite}>
						Overwrite existing offer
					</div>
				</div>
			)}
		</div>
	);
};
