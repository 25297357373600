import React from "react";
import { isIntallationType } from "@repo/api/materialTypeConversion";

import type { SearchableTerm } from "../types";

import s from "./styles.module.css";

type SuggestionRowProps = {
	searchTerm: string;
	rowValue: string;
	selected: boolean;
	sku: string;
	type: string;
	onClick: (suggestion: SearchableTerm) => void;
	clickReturnValue: SearchableTerm;
};

export const SuggestionRow = ({
	rowValue,
	searchTerm,
	selected,
	onClick,
	clickReturnValue,
	sku,
	type,
}: SuggestionRowProps) => {
	const regex = new RegExp(searchTerm, "gi");
	const newText = rowValue.replace(
		regex,
		`<span class="${s.highlight}">$&</span>`,
	);

	const skuCode = `<span class="${s.skuCode}">${sku}</span>`;

	const handleSuggestionClick = () => {
		onClick(clickReturnValue);
	};

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: No need to add keyboard event
		<li
			// biome-ignore lint/a11y/noNoninteractiveElementToInteractiveRole: <explanation>
			role="option"
			aria-selected={selected}
			className={selected ? `${s.option} ${s.selected}` : s.option}
			ref={(li) => {
				selected && li?.scrollIntoView(false);
			}}
			// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
			dangerouslySetInnerHTML={{
				__html: `<span>${newText}</span>${
					isIntallationType(type) ? skuCode : ""
				}`,
			}}
			onClick={handleSuggestionClick}
		/>
	);
};
