import React from "react";
import { useRef, type WheelEvent } from "react";
import type { SearchableTerm, SuggestionItem } from "./Autocomplete/types";
import { Autocomplete } from "./Autocomplete";
import { CurrencyInput } from "./CurrencyInput";
import { Delete, Minus, Plus } from "../icons";

import s from "./styles.module.css";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

const DISCOUNT_SKU_INITIAL_CHARS = "99DISC";

export type LineItemProps = {
	isDiscount: boolean;
	index: number;
	sku: string | undefined;
	name: string | undefined;
	type: string | undefined;
	priceIncludingTaxes: string;
	priceExcludingTaxes: string;
	cost: string;
	quantity: string;
	subtotal: string;
	hasAdd: boolean;
	suggestionTerms: Array<SuggestionItem>;
	onChangeQuantityClick: (sku: string, quantity: number) => void;
	onChangeDiscount: (index: number, value: number) => void;
	onDeleteClick: (index: number) => void;
	onAddNewItemClick: () => void;
	onSelectNewMaterial: (suggestion: SearchableTerm) => void;
};

export const LineItem = (lineItem: LineItemProps) => {
	const quantityRef = useRef<HTMLInputElement>(null);
	const isFetching = useIsFetching();
	const isMutating = useIsMutating();
	const hasSkeletonLoader = isFetching + isMutating > 0;

	const {
		isDiscount,
		index,
		sku = "",
		name,
		type,
		priceIncludingTaxes,
		priceExcludingTaxes,
		cost,
		quantity,
		subtotal,
		hasAdd,
		suggestionTerms,
		onChangeQuantityClick,
		onChangeDiscount,
		onDeleteClick,
		onAddNewItemClick,
		onSelectNewMaterial,
	} = lineItem;

	const handleDeleteClick = () => {
		onDeleteClick(index);
	};

	const handleOnWheel = (event: WheelEvent<HTMLInputElement>) => {
		// Prevent the input value change
		(event.target as HTMLInputElement).blur();
		// Prevent the page/container scrolling
		event.stopPropagation();

		// Refocus immediately, on the next tick (after the current
		//function is done)
		setTimeout(() => {
			(event.target as HTMLInputElement).focus();
		}, 0);
	};

	const handleOnChangeQuantity = () => {
		const newQuantity = Number(quantityRef?.current?.value);
		onChangeQuantityClick(sku, newQuantity);
	};

	const handleMinusClick = () => {
		const numericQuantity = Number(quantity);
		if (numericQuantity > 1) {
			const newQuantity = numericQuantity - 1;
			onChangeQuantityClick(sku, newQuantity);
		}
	};

	const handlePlusClick = () => {
		const newQuantity = Number(quantity) + 1;
		onChangeQuantityClick(sku, newQuantity);
	};

	const handleOnFocus = () => {
		quantityRef?.current?.select();
	};

	const handleOnChangeDiscount = (value: number) => {
		onChangeDiscount(index, value);
	};

	return (
		<div className={`${s.row}${hasSkeletonLoader ? " skeleton" : ""}`}>
			<div className={s.productName}>
				<Autocomplete
					terms={suggestionTerms}
					initialValue={`${name}`}
					sku={sku}
					type={`${type}`}
					onChange={onSelectNewMaterial}
				/>
			</div>
			<div className={s.cost}>{!isDiscount && <span>{cost} €</span>}</div>
			<div className={s.price}>
				{!isDiscount && <span>{priceExcludingTaxes} €</span>}
			</div>
			<div className={s.price}>
				{!isDiscount && <span>{priceIncludingTaxes} €</span>}
				{isDiscount && (
					<span>
						<CurrencyInput
							initialValue={priceIncludingTaxes}
							onChange={handleOnChangeDiscount}
							hasNegativeSign={sku?.startsWith(DISCOUNT_SKU_INITIAL_CHARS)}
						/>
					</span>
				)}
			</div>
			<div className={s.quantity}>
				{!isDiscount && (
					<>
						<div
							className={s.minus}
							onClick={handleMinusClick}
							onKeyDown={handleMinusClick}
						>
							<Minus />
						</div>
						<input
							type="number"
							min={1}
							onWheel={handleOnWheel}
							onChange={handleOnChangeQuantity}
							onFocus={handleOnFocus}
							ref={quantityRef}
							value={quantity}
						/>
						<div
							className={s.plus}
							onClick={handlePlusClick}
							onKeyDown={handlePlusClick}
						>
							<Plus />
						</div>
					</>
				)}
			</div>
			<div className={s.subtotal}>{subtotal} €</div>
			<div
				className={s.delete}
				onClick={handleDeleteClick}
				onKeyDown={handleDeleteClick}
			>
				<Delete />
			</div>
			<div className={s.newItem}>
				{hasAdd && (
					<div
						className={s.add}
						onClick={onAddNewItemClick}
						onKeyDown={onAddNewItemClick}
					>
						<Plus />
					</div>
				)}
			</div>
		</div>
	);
};
