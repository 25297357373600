// biome-ignore lint/style/useImportType: <explanation>
import React, { useEffect } from "react";
import { useState } from "react";
import type { Action } from "@repo/state/modalResponseReducer";

import { OfferButton } from "./offer-button/OfferButton";
import styles from "./styles.module.css";
import type { MaterialsApiResponseModel } from "@repo/api/models";
import { PriceWarning } from "../price-warning/PriceWarning";

type ActionsBarProps = {
	dispatchModal: React.Dispatch<Action>;
	modalToogle: () => void;
	materialsPriceDifferences?: MaterialsApiResponseModel["materials"];
};

export const ActionsBar = ({
	dispatchModal,
	modalToogle,
	materialsPriceDifferences,
}: ActionsBarProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [hasWarning, setHasWarning] = useState(
		(materialsPriceDifferences || []).length > 0,
	);

	useEffect(() => {
		setHasWarning((materialsPriceDifferences || []).length > 0);
	}, [materialsPriceDifferences]);

	const handleCloseClick = () => {
		setHasWarning(false);
	};

	return (
		<div className={styles.layoutEditOffer}>
			<div className={styles.wrapper}>
				<h1 className={styles.title}>Edit offer line items</h1>
				{hasWarning && (
					<PriceWarning
						onClickClose={handleCloseClick}
						dispatch={dispatchModal}
						modalToogle={modalToogle}
					/>
				)}
			</div>
			<OfferButton
				dispatch={dispatchModal}
				isExpanded={isExpanded}
				setExpandedState={setIsExpanded}
				modalToogle={modalToogle}
			/>
		</div>
	);
};
