import React, { type ChangeEvent, useRef, useState } from "react";
import {
	convertToNumber,
	removeCurrencyDisplayValue,
	signedMoneyDisplayValue,
} from "../../../helpers/format";

import s from "./styles.module.css";

export const CurrencyInput = ({
	initialValue,
	onChange,
	hasNegativeSign = false,
}: {
	initialValue: string;
	onChange: (value: number) => void;
	hasNegativeSign?: boolean;
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState(
		signedMoneyDisplayValue(initialValue, hasNegativeSign),
	);

	const handleOnFocus = async () => {
		const unformattedValue = removeCurrencyDisplayValue(value);
		await setValue(unformattedValue);
		inputRef?.current?.select();
	};

	const handleOnBlur = () => {
		const formattedValue = signedMoneyDisplayValue(value, hasNegativeSign);
		setValue(formattedValue);
		if (convertToNumber(initialValue) !== convertToNumber(value)) {
			onChange(
				hasNegativeSign ? -convertToNumber(value) : convertToNumber(value),
			);
		}
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const re = /^[0-9\,\.]+$/;

		if (e.target.value === "" || re.test(e.target.value)) {
			setValue(e.target.value);
		}
	};

	return (
		<input
			className={s.currencyInput}
			type="text"
			ref={inputRef}
			value={value}
			onChange={handleChange}
			placeholder="Enter amount"
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
		/>
	);
};
