export const getMaterials = async (
	url: string,
	getToken: () => Promise<string>,
) => {
	const token = await getToken();
	return await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
