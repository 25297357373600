import * as React from "react";
import { useNavigate, useLocation } from "@tanstack/react-router";
import { useSuspenseQueries, useQueryClient } from "@tanstack/react-query";
import { getOfferQueryOptions } from "@repo/api/getOfferQueryOptions";
import { getMaterialsQueryOptions } from "@repo/api/getMaterialsQueryOptions";
import type { OfferModel, MaterialsModel } from "@repo/api/models";
import { useAuth } from "@repo/api/auth";
import { reducer } from "@repo/state/reducers";
import {
	modalResponseReducer,
	modalResponseInitialState,
} from "@repo/state/modalResponseReducer";
import { CustomerDetails } from "@repo/ui/CustomerDetails";
import { ActionsBar } from "@repo/ui/ActionsBar";
import { LineItems } from "@repo/ui/LineItems";
import { useModal } from "@repo/ui/hooks";
import { MaterialsPriceDifferences } from "../utils/price.utils";
import { ModalManager } from "../components/ModalManager";
import { useOfferMutations } from "../utils/mutations";
import { calculateLineItemsSummary } from "../utils/lineItemsSummary";
import { Route } from "../routes/_auth.offer-creation";

import { getSummary } from "@repo/ui/getSummary";

import s from "../styles/offer-creation.module.css";

const apiUrl = import.meta.env.VITE_APP_API_URL;

export function OfferPage() {
	const { isShowing, toggle } = useModal();
	const auth = useAuth();
	const { offerCollectionID: routeOfferId, customerID } = Route.useSearch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();

	const materialsUrl = `${apiUrl}/materials`;
	const offersUrl = `${apiUrl}/offers/${routeOfferId}`;

	const [offerResponse, materialsResponse] = useSuspenseQueries({
		queries: [
			getOfferQueryOptions(offersUrl, auth.getToken),
			getMaterialsQueryOptions(materialsUrl, auth.getToken),
		],
	});

	const offer = offerResponse.data as OfferModel;
	const materials = (materialsResponse?.data as MaterialsModel).materials;

	const { createOfferMutation, updateOfferMutation, calculateOfferMutation } =
		useOfferMutations(apiUrl, auth.getToken, routeOfferId);

	const [state, dispatch] = React.useReducer(reducer, offer);
	const [stateModalResponse, dispatchModalResponse] = React.useReducer(
		modalResponseReducer,
		modalResponseInitialState,
	);

	const updateMargin = async () => {
		const responseCalculation = await calculateOfferMutation.mutateAsync({
			lineItems: offer.lineItems,
			vat: offer.vat,
		});

		await getSummary({
			responseCalculation,
			dispatchModal: dispatchModalResponse,
			dispatch,
		});
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	React.useEffect(() => {
		updateMargin();
	}, []);

	React.useEffect(() => {
		const newOfferID = stateModalResponse.newOfferID;

		if (newOfferID) {
			navigate({
				to: location.pathname,
				search: {
					offerCollectionID: newOfferID,
					customerID,
				},
			});

			queryClient.invalidateQueries({ queryKey: ["offerKey"] });
		}
	}, [
		stateModalResponse.newOfferID,
		customerID,
		location.pathname,
		navigate,
		queryClient,
	]);

	const materialsPriceDifferences = React.useMemo(
		() => MaterialsPriceDifferences(state.lineItems, materials, state.vat),
		[state.lineItems, materials, state.vat],
	);

	const offerToPost = React.useMemo(
		() => ({
			...state,
			lastModifiedBy: `${auth.user?.toLocaleLowerCase()}@sunhero.com`,
		}),
		[state, auth.user],
	);

	const lineItemsSummary = React.useMemo(
		() => calculateLineItemsSummary(state),
		[state],
	);

	return (
		<div className={isShowing ? `${s.content} ${s.blockScroll}` : s.content}>
			<ActionsBar
				dispatchModal={dispatchModalResponse}
				modalToogle={toggle}
				materialsPriceDifferences={materialsPriceDifferences}
			/>
			<CustomerDetails
				details={[
					{ label: "Opportunity Name:", value: offer.opportunityName },
					{ label: "Offer ID:", value: routeOfferId },
				]}
				offerId={`${routeOfferId}`}
				documents={[]}
			/>
			<LineItems
				calculationSummary={lineItemsSummary}
				lineItemsList={state.lineItems}
				suggestionTerms={materials.suggestionTerms}
				materialsList={materials.list}
				dispatch={dispatch}
				dispatchModal={dispatchModalResponse}
				vat={state.vat}
				mutation={calculateOfferMutation}
			/>

			<ModalManager
				isShowing={isShowing}
				toggle={toggle}
				stateModalResponse={stateModalResponse}
				dispatch={dispatch}
				dispatchModalResponse={dispatchModalResponse}
				offerToPost={offerToPost}
				offerId={routeOfferId}
				opportunityName={offer.opportunityName}
				mutation={createOfferMutation}
				mutationUpdate={updateOfferMutation}
				mutationCalculation={calculateOfferMutation}
				materialsPriceDifferences={materialsPriceDifferences}
			/>
		</div>
	);
}
