import type {
	LineItemModel,
	MaterialApiResponseModel,
	MaterialsApiResponseModel,
	OfferModel,
} from "@repo/api/models";
import { calculatePriceWithoutTax } from "./utils/price";

export enum LineItemsActionKind {
	REMOVE = "REMOVE",
	REMOVE_ALL_EMPTY = "REMOVE_ALL_EMPTY",
	CHANGE_QUANTITY = "CHANGE_QUANTITY",
	CHANGE_PRICE = "CHANGE_PRICE",
	ADD_NEW = "ADD_NEW",
	ADD_NEW_LINE_ITEM = "ADD_NEW_LINE_ITEM",
	PRICE_REFRESH = "PRICE_REFRESH",
	CALCULATION_SUMMARY = "CALCULATION_SUMMARY",
}

export interface LineItemsActionPayload {
	sku?: string;
	quantity?: number;
	price?: number;
	index?: number;
	lineItems?: Array<LineItemModel>;
	materialsPriceDifferences?: MaterialsApiResponseModel["materials"];
	totalCost?: string;
	marginPercentage?: string;
	totalPriceExcludingTaxes?: string;
	totalPriceIncludingTaxes?: string;
	totalAmountIncludingDiscounts?: string;
}

export interface LineItemsAction {
	type: LineItemsActionKind;
	payload: LineItemsActionPayload;
}

export function reducer(
	state: OfferModel,
	action: LineItemsAction,
): OfferModel {
	const { type, payload } = action;
	switch (type) {
		case LineItemsActionKind.REMOVE: {
			const { index } = payload;
			const newLineItem: LineItemModel = {};
			const newLineItems = state.lineItems
				.slice(0, index)
				.concat(state.lineItems.slice((index || 0) + 1));

			return {
				...state,
				lineItems: newLineItems.length > 0 ? newLineItems : [newLineItem],
			};
		}

		case LineItemsActionKind.REMOVE_ALL_EMPTY: {
			const newLineItem: LineItemModel = {};
			const newLineItems = state.lineItems.reduce((acc, lineItem) => {
				if (lineItem.sku) {
					acc.push(lineItem);
				}
				return acc;
			}, [] as LineItemModel[]);

			return {
				...state,
				lineItems: newLineItems.length > 0 ? newLineItems : [newLineItem],
			};
		}

		case LineItemsActionKind.CHANGE_QUANTITY: {
			const { sku, quantity } = payload;
			return {
				...state,
				lineItems: state.lineItems.map((lineItem: LineItemModel) => {
					if (lineItem.sku === sku) {
						const newQuantity = quantity || 1;
						lineItem.subtotal =
							// biome-ignore lint/style/noNonNullAssertion: <explanation>
							Number(lineItem.priceIncludingTaxes)! * newQuantity;
						lineItem.quantity = newQuantity;
					}
					return lineItem;
				}),
			};
		}

		case LineItemsActionKind.CHANGE_PRICE: {
			const { index, price } = payload;
			return {
				...state,
				lineItems: state.lineItems.map(
					(lineItem: LineItemModel, listIndex: number) => {
						if (listIndex === index) {
							lineItem.priceIncludingTaxes = `${price}`;
							lineItem.priceExcludingTaxes = `${Math.floor(
								calculatePriceWithoutTax(Number(price), Number(state.vat)),
							)}`;
							lineItem.subtotal = price;
						}
						return lineItem;
					},
				),
			};
		}

		case LineItemsActionKind.ADD_NEW: {
			const newLineItem: LineItemModel = {};
			return {
				...state,
				lineItems: state.lineItems.concat([newLineItem]),
			};
		}

		case LineItemsActionKind.ADD_NEW_LINE_ITEM: {
			const { lineItems } = payload;
			const cleanLineItems = state.lineItems.filter((lineItem) => lineItem.sku);
			return {
				...state,
				lineItems: cleanLineItems.concat(lineItems || []),
			};
		}

		case LineItemsActionKind.PRICE_REFRESH: {
			const { lineItems, materialsPriceDifferences } = payload;
			return {
				...state,
				lineItems:
					lineItems?.map((lineItem) => {
						const material = materialsPriceDifferences?.find(
							(material: MaterialApiResponseModel) =>
								material.sku === lineItem.sku,
						);
						if (material) {
							return {
								...lineItem,
								cost: material.prime_cost.toString(),
								priceIncludingTaxes:
									material.price_with_margin_and_taxes.toString(),
								priceExcludingTaxes: material.price.toString(),
								quantity: 1,
								subtotal: Number(
									material.price_with_margin_and_taxes.toString(),
								),
							};
						}
						return lineItem;
					}) || [],
			};
		}

		case LineItemsActionKind.CALCULATION_SUMMARY: {
			const newState = {
				...state,
				...payload,
			};
			const emptyLineItems = state.lineItems.filter(
				(lineItem) => !lineItem.sku,
			);
			newState.lineItems = newState.lineItems.concat(emptyLineItems);
			return newState;
		}
		default:
			return state;
	}
}
