import React from "react";

export const Success = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
		>
			<title>Success Icon</title>
			<path
				d="M27.3068 40.7683L36.5376 49.9991L52.6914 29.9991M67.3068 39.9991C67.3068 55.0807 55.0807 67.3068 39.9991 67.3068C24.9175 67.3068 12.6914 55.0807 12.6914 39.9991C12.6914 24.9175 24.9175 12.6914 39.9991 12.6914C55.0807 12.6914 67.3068 24.9175 67.3068 39.9991Z"
				stroke="#55D9A6"
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
};
