import type { OfferMutationModel, OfferApiMutationModel } from "../models";
import { toDto } from "../models";

export const mutationOffer = async (
	url: string,
	getToken: () => Promise<string>,
	offer: OfferMutationModel,
	method: "POST" | "PUT",
) => {
	const token = await getToken();
	const offerToApiModel = toDto(offer) as OfferApiMutationModel;

	return await fetch(url, {
		method: method,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(offerToApiModel),
	});
};
