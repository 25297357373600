import React from "react";
import s from "./styles.module.css";

export const Header = () => (
	<div className={s.wrapper}>
		<div className={s.productName}>Product/Service Name</div>
		<div />
		<div className={s.cost}>Cost</div>
		<div className={s.price}>Price (exc.tax)</div>
		<div className={s.price}>Price (inc.tax)</div>
		<div className={s.quantity}>Quantity</div>
		<div className={s.subtotal}>Subtotal (inc.tax)</div>
		<div />
		<div />
	</div>
);
