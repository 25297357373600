import React from "react";
import { DropdownContext } from ".";
import { DropdownIcon } from "../icons";

import s from "./styles.module.css";

export function DropdownButton({ children }: { children: React.ReactNode }) {
	const { open, setOpen } = React.useContext(DropdownContext); // get the context
	function toggleOpen() {
		setOpen(!open);
	}

	return (
		<button
			type="button"
			onClick={toggleOpen}
			className={[s.button, open ? s.expanded : s.closed].join(" ")}
		>
			{children}
			<DropdownIcon />
		</button>
	);
}
