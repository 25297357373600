import React from "react";

import type { ReactNode } from "react";

import styles from "./styles.module.css";

export interface ButtonProps {
	children: ReactNode;
	isDisabled?: boolean;
	onClick?: () => void;
	type: "submit" | "button";
	variant: "green" | "white" | "yellow" | "grey";
}

export const Button = ({
	children,
	isDisabled,
	onClick,
	type,
	variant,
}: ButtonProps) => {
	return (
		<button
			onClick={onClick}
			className={`${styles.button} ${styles[variant]} ${
				isDisabled ? styles.disabled : ""
			}`}
			type={type}
			disabled={isDisabled}
		>
			{children}
		</button>
	);
};
