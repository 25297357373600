import React, { useEffect, useRef } from "react";
import type { ChangeEvent, KeyboardEvent } from "react";
import { Search } from "../../../icons";

import s from "./styles.module.css";

export type AutocompleteInputProps = {
	id: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export const AutocompleteInput = (props: AutocompleteInputProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const { id, value, onChange, onKeyDown } = props;

	return (
		<div className={s.search}>
			<input
				id={id}
				type="search"
				ref={inputRef}
				value={value}
				placeholder="Type to start searching"
				autoComplete="off"
				aria-autocomplete="list"
				aria-expanded={value.length > 0}
				aria-controls="suggestions"
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
			<Search />
		</div>
	);
};
