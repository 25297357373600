import { queryOptions } from "@tanstack/react-query";

import { offerKey } from "./keys";
import { getOffer } from "../api/getOffer";
import {
	fromDto,
	type OfferApiResponseModel,
} from "../models/offer-response.model";
import { handleQueryFunctionResponse } from "../helpers/helpers";
import type { OfferModel } from "../models";

export const getOfferQueryOptions = (
	url: string,
	getToken: () => Promise<string>,
) =>
	queryOptions({
		queryKey: [offerKey],
		retry: false,
		queryFn: () =>
			handleQueryFunctionResponse<OfferApiResponseModel, OfferModel>({
				apiFunction: getOffer,
				url,
				getToken,
				fromDto,
			}),
	});
