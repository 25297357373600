import * as React from "react";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { QueryClient } from "@tanstack/react-query";
import type { AuthContext } from "../../../../packages/api/src/auth";

import s from "../styles/styles.module.css";

const TanStackRouterDevtools =
	process.env.NODE_ENV === "production"
		? () => null // Render nothing in production
		: React.lazy(() =>
				// Lazy load in development
				import("@tanstack/router-devtools").then((res) => ({
					default: res.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			);

interface MyRouterContext {
	auth: AuthContext;
	queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
	component: () => (
		<>
			<div className={s["page-container"]}>
				<div className={s.content}>
					<Outlet />
					<ReactQueryDevtools />
					<React.Suspense>
						<TanStackRouterDevtools
							position="bottom-left"
							initialIsOpen={false}
						/>
					</React.Suspense>
				</div>
			</div>
		</>
	),
});
