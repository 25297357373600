import React from "react";
import type { ReactNode } from "react";

import s from "./styles.module.css";
import { MegaWattsLogo } from "../icons/MegaWattsLogo";

export const AuthenticationCard = ({ children }: { children: ReactNode }) => {
	return (
		<div className={s.layout}>
			<div className={s.content}>
				<div>
					<MegaWattsLogo />
				</div>
				<div className={s["copy-container"]}>
					<p className={s["tool-motto"]}>
						The new Sunhero power tool to design any system combination.
					</p>
					<p className={s["sunhero-motto"]}>
						Let's help more roofs run on solar!
					</p>
				</div>
				{children}
			</div>
		</div>
	);
};
