import React from "react";

export const DropdownIconOutline = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<title>Dropdown Icon Outline</title>
			<path d="M7 10L12 15L17 10" stroke="#00624F" />
		</svg>
	);
};
