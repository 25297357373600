import { useState, useCallback } from "react";

interface UseModalReturn {
	isShowing: boolean;
	toggle: () => void;
}

export const useModal = (): UseModalReturn => {
	const [isShowing, setIsShowing] = useState<boolean>(false);

	const toggle = useCallback(() => {
		setIsShowing((prevState) => !prevState);
	}, []);

	return { isShowing, toggle };
};
