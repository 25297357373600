import type { LineItemsAction } from "@repo/state/reducers";
import type { UseMutationResult } from "@tanstack/react-query";
import {
	type Action as ModalAction,
	ActionKind as ModalActionKind,
} from "@repo/state/modalResponseReducer";
import type {
	OfferModel,
	CalculatePayload,
	MaterialsApiResponseModel,
	MaterialApiResponseModel,
} from "@repo/api/models";

import { getSummary } from "../../helpers/getSummary";

export interface OnUpdatePricesProps {
	dispatch: ({ type, payload }: LineItemsAction) => void;
	dispatchModal: ({ type, payload }: ModalAction) => void;
	mutationCalculation: UseMutationResult<
		Response,
		Error,
		CalculatePayload,
		unknown
	>;
	offerData: OfferModel;
	materialsPriceDifferences?: MaterialsApiResponseModel["materials"];
}

export const onUpdatePrices = async ({
	dispatch,
	dispatchModal,
	mutationCalculation,
	offerData,
	materialsPriceDifferences,
}: OnUpdatePricesProps) => {
	const lineItems =
		offerData.lineItems.map((lineItem) => {
			const material = materialsPriceDifferences?.find(
				(material: MaterialApiResponseModel) => material.sku === lineItem.sku,
			);
			if (material) {
				const subtototal = (
					material.price_with_margin_and_taxes * (lineItem.quantity || 1)
				).toFixed(2);
				return {
					...lineItem,
					cost: material.prime_cost.toString(),
					priceIncludingTaxes: material.price_with_margin_and_taxes.toString(),
					priceExcludingTaxes: material.price.toString(),
					subtotal: Number(subtototal.toString()),
				};
			}
			return lineItem;
		}) || [];

	const responseCalculation = await mutationCalculation.mutateAsync({
		lineItems: lineItems,
		vat: offerData.vat,
	});

	await getSummary({
		responseCalculation,
		dispatchModal,
		dispatch,
	});

	dispatchModal({
		type: ModalActionKind.PRICE_UPDATED,
		payload: { lineItems },
	});
};
