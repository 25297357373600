import { animated, useSpring } from "@react-spring/web";
import React, { useRef, useState } from "react";
import { SmallDropdownIcon } from "../../../icons";

import { useClickOutside } from "../../../../hooks";
import s from "./styles.module.css";

type TaxButtonProps = {
	vat: string;
	onChangeTax: (tax: string) => void;
	cssClass?: string;
};

export const TaxButton = ({ vat, onChangeTax, cssClass }: TaxButtonProps) => {
	const containerRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	useClickOutside(containerRef, () => {
		setIsOpen(false);
	});

	const optionValues = ["7", "10", "21"].filter((value) => value !== vat);

	const style = useSpring({
		from: { height: 0, opacity: 0, y: 60, zIndex: 1 },
		to: {
			height: isOpen ? 60 : 0,
			opacity: isOpen ? 1 : 0,
			y: isOpen ? 0 : 60,
			zIndex: isOpen ? 2 : 1,
		},
	});

	const handleTaxClick = () => {
		setIsOpen(!isOpen);
	};

	const handleChangeTax = (vat: string) => {
		onChangeTax(vat);
		handleTaxClick();
	};

	return (
		<div className={s.container} ref={containerRef}>
			<animated.div className={s.options} style={style}>
				{optionValues.map((option) => (
					<div
						className={s.option}
						key={option}
						onClick={() => handleChangeTax(option)}
						onKeyUp={() => handleChangeTax(option)}
					>
						<span className={s.tax}>{option} % Tax</span>
					</div>
				))}
			</animated.div>
			<div
				className={[s.buttonContainer, isOpen && s.expanded].join(" ")}
				onClick={handleTaxClick}
				onKeyUp={handleTaxClick}
			>
				<span className={`${s.tax} ${cssClass}`}>{vat} % Tax</span>
				<SmallDropdownIcon />
			</div>
		</div>
	);
};
