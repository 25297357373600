import React from "react";

export const Warning = () => {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Warning</title>
			<g id="Group 4">
				<circle
					id="Ellipse 14"
					cx="5.9994"
					cy="5.9994"
					r="5.03846"
					stroke="#B00020"
				/>
				<g id="Warning">
					<path
						d="M6.61554 8.30739C6.61554 8.64726 6.34002 8.92278 6.00015 8.92278C5.66028 8.92278 5.38477 8.64726 5.38477 8.30739C5.38477 7.96752 5.66028 7.69201 6.00015 7.69201C6.34002 7.69201 6.61554 7.96752 6.61554 8.30739Z"
						fill="#B00020"
					/>
					<path
						d="M5.42323 3.23047L5.57707 7.07662H6.42323L6.57707 3.23047H5.42323Z"
						fill="#B00020"
					/>
				</g>
			</g>
		</svg>
	);
};
