import * as React from "react";
import {
	clearStorage,
	getStoredPicture,
	getStoredRedirect,
	getStoredRefreshToken,
	getStoredState,
	getStoredToken,
	getStoredTokenExpiration,
	getStoredUser,
	setStoredRedirect,
	setStoredRefreshToken,
	setStoredState,
	setStoredToken,
	setStoredTokenExpiration,
	setStoredUserAndPicture,
} from "./storage";
import { oauth2GetToken, oauth2RefreshToken, oauth2SignIn } from "./oauth";

export interface AuthContext {
	isAuthenticated: boolean;
	login: (redirectUri: string) => void;
	logout: () => void;
	getToken: () => Promise<string>;
	user: string | null;
	userPicture: string | null;
	redirectUri: string | null;
}

export const AuthContext = React.createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
	const [user, setUser] = React.useState<string | null>(getStoredUser());
	const [userPicture, setUserPicture] = React.useState<string | null>(
		getStoredPicture(),
	);
	const [redirectUri, setRedirectUri] = React.useState<string | null>(
		getStoredRedirect(),
	);
	const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(!!user);

	const logout = React.useCallback(() => {
		clearStorage();
		setUser(null);
		setUserPicture(null);
		setRedirectUri(null);
	}, []);

	const login = React.useCallback((redirectUri: string) => {
		setStoredRedirect(redirectUri);
		const state = setStoredState();
		oauth2SignIn(state);
	}, []);

	const getToken = React.useCallback(async () => {
		const isExpired = getStoredTokenExpiration();

		if (isExpired) {
			const refreshToken = getStoredRefreshToken();

			const getTokenData = await oauth2RefreshToken(refreshToken);

			if ("id_token" in getTokenData) {
				setStoredToken(getTokenData.id_token);
				setStoredTokenExpiration(getTokenData.expires_in);
				return Promise.resolve(getTokenData.id_token);
			}
		}

		return Promise.resolve(getStoredToken());
	}, []);

	// /**
	//  * Get the returned code from the initial google auth call
	//  */
	React.useEffect(() => {
		const getInitialToken = async () => {
			const stateRegex = /state=([^&]+)/;
			const codeRegex = /code=([^&]+)/;
			const state = getStoredState();
			const storedRefreshToken = getStoredRefreshToken();
			const isStateMatch = location.href.match(stateRegex);
			const isCodeMatch = location.href.match(codeRegex);

			if (isStateMatch && isCodeMatch && !storedRefreshToken) {
				const requestState = isStateMatch[1];
				const code = isCodeMatch[1];
				if (requestState === state) {
					const getTokenData = await oauth2GetToken(decodeURIComponent(code));

					if ("id_token" in getTokenData) {
						setIsAuthenticated(true);

						setStoredToken(getTokenData.id_token);
						setStoredUserAndPicture(getTokenData.id_token);
						setStoredRefreshToken(getTokenData.refresh_token);
						setStoredTokenExpiration(getTokenData.expires_in);

						setRedirectUri(getStoredRedirect());
						setUser(getStoredUser());
						setUserPicture(getStoredPicture());
					}
				}
			}
		};

		getInitialToken();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				redirectUri,
				user,
				userPicture,
				login,
				logout,
				getToken,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const context = React.useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
}
