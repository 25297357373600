import type { LineItemsSummary } from "@repo/ui/LineItemsFooter";
import type { OfferModel } from "@repo/api/models";

/**
 * Calculate the summary of line items based on the offer state.
 * @param offerState The current state of the offer.
 * @returns The summary for line items including margins, costs, and taxes.
 */
export const calculateLineItemsSummary = (
	offerState: OfferModel,
): LineItemsSummary => {
	return {
		marginPercentage: offerState?.marginPercentage || "",
		totalAmountIncludingDiscounts:
			offerState?.totalAmountIncludingDiscounts || "",
		totalCost: offerState?.totalCost || "",
		totalPriceExcludingTaxes: offerState?.totalPriceExcludingTaxes || "",
		totalPriceIncludingTaxes: offerState?.totalPriceIncludingTaxes || "",
		vat: offerState?.vat,
	};
};
