import {
	type Action as ModalAction,
	ActionKind as ModalActionKind,
} from "@repo/state/modalResponseReducer";

import {
	LineItemsActionKind,
	type LineItemsAction,
} from "@repo/state/reducers";

import type {
	CalculationSummary,
	CalculationSummaryPayload,
} from "@repo/api/models";

import { lineItemsFromDto } from "@repo/api/models";

export interface GetSummaryProps {
	responseCalculation: Response;
	dispatchModal: ({ type, payload }: ModalAction) => void;
	dispatch: ({ type, payload }: LineItemsAction) => void;
}

export const calculationResponseToSummary = (
	calculation: CalculationSummary,
): CalculationSummaryPayload => {
	const {
		discount_amount,
		discount_percentage,
		margin_amount,
		margin_excluding_discount_amount,
		margin_excluding_discount_percentage,
		margin_percentage,
		total_amount_including_discounts,
		total_cost,
		total_amount_excluding_discounts,
		total_price_excluding_taxes,
		total_price_including_taxes,
		vat,
	} = calculation;

	return {
		discountAmount: discount_amount.toString(),
		discountPercentage: discount_percentage.toString(),
		marginAmount: margin_amount.toString(),
		marginExcludingDiscountAmount: margin_excluding_discount_amount.toString(),
		marginExcludingDiscountPercentage:
			margin_excluding_discount_percentage.toString(),
		marginPercentage: margin_percentage.toString(),
		totalAmount: total_amount_excluding_discounts.toString(),
		totalAmountIncludingDiscounts: total_amount_including_discounts.toString(),
		totalCost: total_cost.toString(),
		totalPriceExcludingTaxes: total_price_excluding_taxes.toString(),
		totalPriceIncludingTaxes: total_price_including_taxes.toString(),
		vat,
	} as CalculationSummaryPayload;
};

const handleError = (dispatchModal: (action: ModalAction) => void) => {
	dispatchModal({
		type: ModalActionKind.ERROR_FETCHING,
		payload: {
			errorFetching: true,
			message:
				"We were not able to execute the calculation successfully. Please, try again and if error persist get in touch with the Tech team.",
			title: "Something went wrong",
			responseType: "error",
			createOffer: false,
			updateOffer: false,
		},
	});
};

export const getSummary = async ({
	responseCalculation,
	dispatchModal,
	dispatch,
}: GetSummaryProps): Promise<CalculationSummaryPayload | undefined> => {
	try {
		if (!responseCalculation.ok) {
			handleError(dispatchModal);
			return;
		}

		const calculation = await responseCalculation.json();
		const summaryPayload = calculationResponseToSummary(calculation);

		dispatch({
			type: LineItemsActionKind.CALCULATION_SUMMARY,
			payload: {
				...summaryPayload,
				lineItems: lineItemsFromDto(calculation.line_items),
			},
		});

		return summaryPayload;
	} catch (error) {
		handleError(dispatchModal);
		console.error("Error fetching calculation summary:", error);
		return;
	}
};
