import React, { useRef, type Dispatch, type SetStateAction } from "react";
import { useClickOutside } from "../../hooks";
import { DropdownButton } from "./DropdownButton";
import { DropdownContent } from "./DropdownContent";
import { DropdownItem } from "./DropdownItem";
import { DropdownList } from "./DropdownList";

import { DropdownIcon } from "../icons";
import s from "./styles.module.css";

type DropdownContextType = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	setValue: Dispatch<SetStateAction<string>>;
};

export const DropdownContext = React.createContext<DropdownContextType>({
	open: false,
	setOpen: () => {},
	setValue: () => {},
});

export function Dropdown({
	children,
	disabled,
}: { children: React.ReactNode; disabled: boolean }) {
	const containerRef = useRef(null);
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState("");

	useClickOutside(containerRef, () => {
		setOpen(false);
	});

	function toggleOpen() {
		setOpen(!disabled && !open);
	}

	return (
		<DropdownContext.Provider value={{ open, setOpen, setValue }}>
			<label className={s.label} ref={containerRef}>
				<span className={s.labelText}>Update reason *</span>
				<select className={s.hidden} name="reason" required>
					<option value={value} selected={value !== ""}>
						{value}
					</option>
				</select>
				<button
					type="button"
					onClick={toggleOpen}
					className={[
						s.button,
						open ? s.expanded : s.closed,
						disabled && s.disabled,
					].join(" ")}
				>
					{value || "Select"}
					<DropdownIcon />
				</button>
				{children}
			</label>
		</DropdownContext.Provider>
	);
}

Dropdown.Button = DropdownButton;
Dropdown.Content = DropdownContent;
Dropdown.List = DropdownList;
Dropdown.Item = DropdownItem;
