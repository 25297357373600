import React from "react";
import { DropdownContext } from ".";

import s from "./styles.module.css";

export function DropdownContent({ children }: { children: React.ReactNode }) {
	const { open } = React.useContext(DropdownContext);

	return open && <div className={s.content}>{children}</div>;
}
