import React from "react";

export const DropdownIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Dropdown Icon</title>
			<g id="Arrow ">
				<path id="icon" d="M17 14L12 9L7 14L17 14Z" fill="white" />
			</g>
		</svg>
	);
};
