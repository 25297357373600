import type { LineItemModel, MaterialsModel } from "@repo/api/models";
import { isDiscount } from "@repo/api/materialTypeConversion";

const multipliers: { [key: string]: number } = {
	"7": 1.13084112149533,
	"21": 1,
	"10": 1,
};

export const MaterialsPriceDifferences = (
	lineItems: LineItemModel[],
	materials: MaterialsModel["materials"],
	vat: string,
): MaterialsModel["materials"]["list"] => {
	const multiplier = multipliers[vat];
	return lineItems.reduce<MaterialsModel["materials"]["list"]>(
		(accumulator, current) => {
			const material = materials.list.find(
				(material) => material.sku === current.sku,
			);
			if (
				material &&
				!isDiscount(material) &&
				Number(material?.price * multiplier).toFixed(2) !==
					Number(current.priceExcludingTaxes).toFixed(2)
			) {
				accumulator.push(material);
			}
			return accumulator;
		},
		[] as MaterialsModel["materials"]["list"],
	);
};
