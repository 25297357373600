import type {
	CalculatePayload,
	OfferModel,
	OfferMutationModel,
} from "@repo/api/models";
import type { UseMutationResult } from "@tanstack/react-query";

import {
	type Action as ModalAction,
	ActionKind as ModalActionKind,
	type SuccessfulCreatedApiPayload,
} from "@repo/state/modalResponseReducer";
import {
	type LineItemsAction,
	LineItemsActionKind,
} from "@repo/state/reducers";

import { getSummary } from "../../helpers/getSummary";

export interface OnGeneratingProps {
	dispatch: ({ type, payload }: LineItemsAction) => void;
	dispatchModal: ({ type, payload }: ModalAction) => void;
	event: React.FormEvent<HTMLFormElement>;
	mutation: UseMutationResult<Response, Error, OfferMutationModel, unknown>;
	mutationCalculation: UseMutationResult<
		Response,
		Error,
		CalculatePayload,
		unknown
	>;
	offerData: OfferModel;
}

export const onGenerating = async ({
	dispatch,
	dispatchModal,
	event,
	mutation,
	mutationCalculation,
	offerData,
}: OnGeneratingProps) => {
	event.preventDefault();

	const selected = event.currentTarget.reason.options.selectedIndex;
	const reasonSelected = event.currentTarget.reason.options[selected].value;
	const description = event.currentTarget["offer-description"].value;

	const responseCalculation = await mutationCalculation.mutateAsync({
		lineItems: offerData.lineItems,
		vat: offerData.vat,
	});

	const summary = await getSummary({
		responseCalculation,
		dispatchModal,
		dispatch,
	});

	const response = summary
		? await mutation.mutateAsync({
				...offerData,
				...summary,
				...{ updateReason: reasonSelected, description },
			})
		: { ok: false };

	if (!response.ok) {
		dispatchModal({
			type: ModalActionKind.ERROR_FETCHING,
			payload: {
				errorFetching: true,
				message:
					"We were not able to generate the offer successfully. Please, try again and if error persist get in touch with the Tech team.",
				title: "Something went wrong",
				responseType: "error",
				createOffer: false,
				updateOffer: false,
			},
		});
	}
	if (response.ok) {
		dispatch({
			type: LineItemsActionKind.REMOVE_ALL_EMPTY,
			payload: {},
		});
		const successfulResponse: SuccessfulCreatedApiPayload = await (
			response as Response
		).json();
		dispatchModal({
			type: ModalActionKind.GENERATED_NEW_OFFER,
			payload: {
				newOfferID: successfulResponse.offer_id,
				newOfferNumber: successfulResponse.offer_number,
				newSfdcOfferLink: successfulResponse.sfdc_offer_link,
			},
		});
	}
};
