import React from "react";
import { type Action, ActionKind } from "@repo/state/modalResponseReducer";
import { Close, Warning } from "../icons";

import s from "./styles.module.css";

type PriceWarningProps = {
	dispatch: ({ type, payload }: Action) => void;
	modalToogle: () => void;
	onClickClose: () => void;
};

export const PriceWarning = ({
	dispatch,
	modalToogle,
	onClickClose,
}: PriceWarningProps) => {
	const handleOnClickRefresh = () => {
		dispatch({
			type: ActionKind.PRICE_DIFFERENCE,
		});
		modalToogle();
	};

	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<div className={s.warning}>
					<Warning />
					<span className={s.copy}>Price differences detected</span>
				</div>
				<span
					className={s.link}
					onClick={handleOnClickRefresh}
					onKeyUp={handleOnClickRefresh}
				>
					Learn more
				</span>
				<div role="button" onClick={onClickClose} onKeyUp={onClickClose}>
					<Close />
				</div>
			</div>
		</div>
	);
};
