// mutations.ts
import { useMutation } from "@tanstack/react-query";
import { mutationOffer } from "@repo/api/mutationOffer";
import { mutationCalculate } from "@repo/api/mutationCalculate";
import type { OfferMutationModel, CalculatePayload } from "@repo/api/models";

export const useOfferMutations = (
	apiUrl: string,
	getToken: () => Promise<string>,
	offerId: string,
) => {
	// Mutation for creating a new offer
	const createOfferMutation = useMutation({
		mutationFn: async (offerData: OfferMutationModel) => {
			const url = `${apiUrl}/offers`;
			return await mutationOffer(url, getToken, offerData, "POST");
		},
	});

	// Mutation for updating an existing offer
	const updateOfferMutation = useMutation({
		mutationFn: async (offerData: OfferMutationModel) => {
			const url = `${apiUrl}/offers/${offerId}`;
			return await mutationOffer(url, getToken, offerData, "PUT");
		},
	});

	// Mutation for calculating the offer payload
	const calculateOfferMutation = useMutation({
		mutationFn: async (payload: CalculatePayload) => {
			const url = `${apiUrl}/calculate`;
			return await mutationCalculate(url, getToken, payload);
		},
	});

	return {
		createOfferMutation,
		updateOfferMutation,
		calculateOfferMutation,
	};
};
