import type { MaterialsModel } from "./materials.model";
import type { LineItemModel } from "./offer.model";

export interface MaterialApiResponseModel {
	readonly name: string;
	readonly prime_cost: number;
	readonly sku: string;
	readonly display_name: string;
	readonly brand: string | null;
	readonly price: number;
	readonly description: string;
	readonly price_with_margin_and_taxes: number;
	readonly material_type: string;
	readonly quantity: number;
}

export interface MaterialWithAssociatedMaterialsApiResponseModel
	extends MaterialApiResponseModel {
	readonly associated_materials: readonly MaterialApiResponseModel[];
}

export interface MaterialsApiResponseModel {
	readonly materials: readonly MaterialWithAssociatedMaterialsApiResponseModel[];
}

/**
 * Transform an API model to a Domain model
 * @param dto API response model
 * @returns Domain model
 */
export function fromDto(dto: MaterialsApiResponseModel): MaterialsModel {
	return {
		materials: {
			list: dto.materials.map((material) => material),
			suggestionTerms: dto.materials.map((material) => ({
				id: material.sku,
				name: material.display_name,
				type: material.material_type,
				sku: material.sku,
			})),
		},
	};
}

/**
 * Helper function to transform a single material to LineItemModel
 * @param material Material to transform
 * @returns LineItemModel
 */
function mapToLineItem(material: MaterialApiResponseModel): LineItemModel {
	return {
		sku: material.sku,
		name: material.display_name,
		type: material.material_type,
		priceIncludingTaxes: `${Number(
			material.price_with_margin_and_taxes,
		).toFixed(2)}`,
		priceExcludingTaxes: `${Number(material.price).toFixed(2)}`,
		cost: `${Number(material.prime_cost).toFixed(2)}`,
		quantity: material.quantity || 1,
		subtotal: Number(
			(material.price_with_margin_and_taxes * (material.quantity || 1)).toFixed(
				2,
			),
		),
	};
}

/**
 * Converts a material and its associated materials to LineItemModel[]
 * @param material Material with associated materials
 * @returns Array of LineItemModel
 */
export function toLineItemModel(
	material?: MaterialWithAssociatedMaterialsApiResponseModel,
): LineItemModel[] {
	if (!material) return [];

	// Main material line item
	const mainLineItem = mapToLineItem(material);

	// Associated materials line items
	const associatedLineItems = (material.associated_materials || []).map(
		mapToLineItem,
	);

	return [mainLineItem, ...associatedLineItems];
}
