import React from "react";

import { GreenUppercaseLabel } from "../green-uppercase-label/GreenUppercaseLabel";
import styles from "./styles.module.css";

export interface detailRowProps {
	label: string;
	value?: string;
}

interface CustomerDetailsProps {
	variant?: "detailTitle" | "detailRow";
	details: detailRowProps;
	hasDetailsText: boolean;
}

export const Detail = ({
	variant,
	hasDetailsText,
	details,
}: CustomerDetailsProps) => {
	return (
		<div className={styles.layoutClientDetails}>
			{hasDetailsText && variant === "detailTitle" && (
				<>
					<GreenUppercaseLabel>{details.label}</GreenUppercaseLabel>
					<span className={styles.clientDetailsText}>{details.value}</span>
				</>
			)}
			{!hasDetailsText && variant === "detailTitle" && (
				<GreenUppercaseLabel>{details.label}</GreenUppercaseLabel>
			)}
			{hasDetailsText && variant === "detailRow" && (
				<>
					<span className={styles.rowLabel}>{details.label}</span>
					<span className={styles.clientDetailsText}>{details.value}</span>
				</>
			)}
		</div>
	);
};
