import React, { type ReactNode } from "react";
import { DropdownContext } from ".";

import s from "./styles.module.css";

export function DropdownItem({ children }: { children: ReactNode }) {
	const { setValue, setOpen } = React.useContext(DropdownContext);

	const handleItemClick = (event: React.KeyboardEvent | React.MouseEvent) => {
		event.preventDefault();
		setValue(children as string);
		setOpen(false);
	};

	return (
		<li
			className={s.item}
			onClick={handleItemClick}
			onKeyUp={handleItemClick}
			aria-label={children as string}
		>
			{children}
		</li>
	);
}
