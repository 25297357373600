import type { ReactNode } from "react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { Close } from "../icons";
import styles from "./styles.module.css";

export interface ModalProps {
	children: ReactNode;
	onCloseButtonClick: () => void;
}

export const Modal = ({ onCloseButtonClick, children }: ModalProps) => {
	useEffect(() => {
		window.document.body.style.height = "100%";
		window.document.body.style.overflow = "hidden";

		return () => {
			window.document.body.style.height = "";
			window.document.body.style.overflow = "";
		};
	}, []);

	return ReactDOM.createPortal(
		<div className={styles.modalWrapper}>
			<div className={styles.layout}>
				<div className={styles.closeBar}>
					<button
						className={styles.closeButton}
						type="button"
						onClick={onCloseButtonClick}
					>
						<Close />
					</button>
				</div>

				{children}
			</div>
		</div>,
		document.body,
	);
};
