import React from "react";

export const Search = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<title>search</title>
			<path
				d="M10.5033 9.50326H9.97659L9.78992 9.32326C10.4433 8.56326 10.8366 7.57659 10.8366 6.50326C10.8366 4.10992 8.89659 2.16992 6.50326 2.16992C4.10992 2.16992 2.16992 4.10992 2.16992 6.50326C2.16992 8.89659 4.10992 10.8366 6.50326 10.8366C7.57659 10.8366 8.56326 10.4433 9.32326 9.78992L9.50326 9.97659V10.5033L12.8366 13.8299L13.8299 12.8366L10.5033 9.50326ZM6.50326 9.50326C4.84326 9.50326 3.50326 8.16326 3.50326 6.50326C3.50326 4.84326 4.84326 3.50326 6.50326 3.50326C8.16326 3.50326 9.50326 4.84326 9.50326 6.50326C9.50326 8.16326 8.16326 9.50326 6.50326 9.50326Z"
				fill="#3C3C3B"
				fillOpacity="0.8"
			/>
		</svg>
	);
};
