// biome-ignore lint/style/useImportType: <explanation>
import React, { useCallback } from "react";
import { Button } from "../button/Button";
import type { OnOverwriteProps } from "./onOverwrite";
import { onOverwrite } from "./onOverwrite";

import styles from "./styles.module.css";

export interface OverwriteProps extends OnOverwriteProps {
	opportunityName: string;
	offerId: string;
	onCloseButtonClick: () => void;
}

export const Overwrite = ({
	opportunityName,
	offerId,
	onCloseButtonClick,
	mutation,
	mutationCalculation,
	offerData,
	dispatch,
	dispatchModal,
}: OverwriteProps) => {
	const handleOnOverwrite = useCallback(
		() =>
			onOverwrite({
				dispatch,
				dispatchModal,
				mutation,
				mutationCalculation,
				offerData,
			}),
		[dispatch, dispatchModal, mutation, mutationCalculation, offerData],
	);

	const hasPendindOperation =
		mutation?.isPending || mutationCalculation?.isPending;

	return (
		<div className={styles.layout}>
			<p className={styles.title}>Overwrite existing offer</p>
			<div className={styles.copyForm}>
				<p className={styles.description}>
					Please confirm you want to overwrite the Offer and Opportunity shown
					below. Remember it will mean losing any previous data stored.
				</p>
			</div>
			<div className={styles.offerDetails}>
				<div className={styles.offerDetail}>
					<span className={styles.labelDetail}>Opportunity Name:</span>
					<span className={styles.textDetail}>{opportunityName}</span>
				</div>
				<hr className={styles.divider} />
				<div className={styles.offerDetail}>
					<span className={styles.labelDetail}>Offer ID:</span>
					<span className={styles.textDetail}>{offerId}</span>
				</div>
			</div>
			<div className={styles.cta}>
				<Button
					isDisabled={hasPendindOperation}
					onClick={onCloseButtonClick}
					variant="grey"
					type="button"
				>
					cancel
				</Button>
				<Button
					isDisabled={hasPendindOperation}
					onClick={handleOnOverwrite}
					type="button"
					variant="yellow"
				>
					overwrite offer
				</Button>
				{hasPendindOperation && (
					<span role="alert" aria-busy="true" className={styles.loader} />
				)}
			</div>
		</div>
	);
};
